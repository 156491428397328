import React from 'react';
import { graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, } from 'swiper';
import Layout from '../components/Layout';
import Img from 'gatsby-image';
import Seo from '../components/Seo';
import '../styles/components/project.scss';
import '../styles/components/full-gallery.scss';
import '../styles/components/swiper-controls.scss';

import 'swiper/css';


import SwiperCore, {
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

const customCursor = (e) => {

	const body = document.body;
	const cursor = document.querySelector( '.cursor' );
	const theWindow = window.innerWidth;
	const centre = theWindow / 2;

	cursor.style.left = e.clientX + "px";
	cursor.style.top = e.clientY + "px";

	if( e.clientX > centre ) {
		cursor.classList.add( 'cursor--flipped' );
	} else {
		cursor.classList.remove( 'cursor--flipped' );
	}

	body.addEventListener( "mousemove", (n, event) => {
		cursor.style.left = n.clientX + "px";
		cursor.style.top = n.clientY + "px";

		if( n.clientX > centre ) {
			cursor.classList.add( 'cursor--flipped' );
		} else {
			cursor.classList.remove( 'cursor--flipped' );
		}

	});

}

SwiperCore.use([Keyboard, Navigation, Pagination]);

let slide, img;

const ProjectTemplate = ({data}) => {
	return (
		<>
			<Layout>
			<Seo 
				bodyClasses="bg:body project-page reveal-header reveal-gallery"
		    	siteTitle={data.wp.generalSettings.title}
				siteDescription={data.wp.generalSettings.description}
				title={data.wpProject.title}
				metaDesc={data.wpProject.seo.metaDesc}
				focusKeywords={data.wpProject.seo.focuskw}
				opengraphTitle={data.wpProject.seo.opengraphTitle}
				opengraphDescription={data.wpProject.seo.opengraphDescription}
				twitterDescription={data.wpProject.seo.twitterDescription}
				twitterImage={data.wpProject.seo.twitterImage}
				twitterTitle={data.wpProject.seo.twitterTitle}
		    />
		    <article className={ 'modal modal:light visual-index-modal' }>
				<Swiper 
					keyboard={{
						enabled: true
					}} 
					navigation={{
						prevEl: '.slide-prev',
						nextEl: '.slide-next'
					}}
					pagination={{
						el: '.pagination',
						type: 'fraction'
					}}
					modules={[EffectFade]}
					effect="fade"
					slidesPerView={ 1 }
					loop={ true }
					speed={ 0 }
					className="full-gallery"
					onInit={() => {
						document.addEventListener( 'mousemove', (e) => {
							customCursor(e);
						});
					}}
					onAfterInit={(swiper) => {
						swiper.slideTo(1)
					}}
					onTransitionEnd={(index, swiper) => {
						slide = document.querySelector( '.swiper-slide-active' );
					}}
				>

				<header className={ 'flex text:header sequence' }>
					<h1>{ data.wpProject.title }</h1>

					{ data.wpProject.categories.nodes[0] && (
						<p className={ 'text:small' }>{ data.wpProject.categories.nodes[0].name }</p>
					)}
				</header>
					{ data.wpProject.visualIndexGallery.visualIndexGallery && 
						data.wpProject.visualIndexGallery.visualIndexGallery.map(( slide, i ) => (
							<SwiperSlide key={i} data-title={ data.wpProject.title }>
								<figure>
								{ slide.image.localFile.extension === 'gif' && (
									<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.image.localFile.publicURL } />
								)}
								{ slide.image.localFile.extension !== 'gif' && (
									<Img 
										style={{
											height: '100vh'
										}}
										imgStyle={{
											objectFit: 'contain',
											height: '100%'
										}}
										fadeIn={false}
										loading="eager"
										fluid={ slide.image.localFile.childImageSharp.fluid }
									/>
								)}
							</figure>
							</SwiperSlide>
						)
					)}

					<div className="slide-prev"></div>
					<div className="slide-next"></div>
				</Swiper>

				<a 
	        		className="close-modal" 
	        		href={ '/visual-index/' }></a>

				<div className="cursor">
					<svg width="51" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.242 0 0 4.243l4.242 4.242V0Z" fill="#fff"/><path d="M2.242 4.243h48" stroke="#fff"/></svg>
				</div>

			</article>
			</Layout>
		</>
	)
}

export default ProjectTemplate;

export const query = graphql`
	query ProjectItem($id: String) {
	  wpProject(id: {eq: $id}) {
	    id
	    title
	    slug
		seo {
		      twitterTitle
		      twitterImage {
		        localFile {
		          publicURL
		        }
		      }
		      opengraphTitle
		      opengraphImage {
		        localFile {
		          childImageSharp {
		            fixed {
		              src
		            }
		          }
		        }
		      }
		      focuskw
		      metaDesc
		      opengraphDescription
		    }
		visualIndexGallery {
	        visualIndexGallery {
	          caption
	          image {
	            localFile {
		            publicURL
	            	extension
	              	childImageSharp {
	                	fluid(maxWidth:1920) {
	                		...GatsbyImageSharpFluid_noBase64
	              		}
	              }
	            }
	          }
	        }
	      }
	    categories {
	      nodes {
	        name
	      }
	    }
	  }
		wp {
			generalSettings {
			title
			description
			}
		}
	}
`;